@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.footer {
  position: relative;
  color: darken($gray-blue, 3%);
  background: $gray-300;
  padding: 80px 0;

  &__column-0 {
    padding-left: 0;
    margin-bottom: 40px;
  }

  &__column-1,
  &__column-2 {
    padding-left: 0;
  }

  &__column-4 {
    padding-left: 0;
    padding-right: 0;
  }

  &__column-3 {
    padding-left: 30px;
  }

  &__column-6 {
    display: none;
  }

  &__section-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $black;
  }

  &__terms-links {
    .footer__section-link {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 15px;

      &:first-child {
        margin-right: 30px;
      }
    }
  }

  &__section-link {
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'ordn' on;
    color: darken($gray-blue, 3%);
    display: block;
    margin-top: 14px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__content {
    font-size: 12px;
    line-height: 19px;
    white-space: pre-line;

    .footer__section-link {
      display: inline;
    }
  }

  nav {
    color: $gray-800;
    text-align: right;
    padding-bottom: 40px;
    border-bottom: 1px solid #c4cfdc;

    a {
      font-size: 15px;
      margin-left: 30px;
      color: $gray-800;

      &::after {
        background: rgba($gray-800, 0.35);
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__copyright {
    padding-top: 40px;
    font-weight: 600;
    font-size: 15px;
    text-align: right;
  }
}

.social-icons {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  width: 66%;

  span {
    display: none;
  }
}

.hide-on-md {
  display: flex;
}

.address {
  margin-top: 15px;
  margin-right: 45px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: darken($gray-blue, 3%);
  width: 145px;
  white-space: pre;
}

.copyright {
  margin-top: 15px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: darken($gray-blue, 3%);
  width: 185px;
  white-space: pre;
}

@include media-breakpoint-up(lg) {
  .footer {
    &__column-0 {
      flex: 0 0 14%;
    }

    &__column-1 {
      flex: 0 0 18%;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer {
    &__column-0 {
      padding-left: 15px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .footer {
    &__content {
      padding-left: 0;
    }

    nav {
      text-align: left;
      border-bottom: 0;
      padding-top: 30px;
      margin-top: 30px;
      padding-bottom: 0;
      border-top: 0;
      display: flex;
      flex-direction: column;

      a {
        width: fit-content;
        flex: 0;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    &__copyright {
      max-width: 100%;
      text-align: left;
    }
  }
}

@include media-breakpoint-down(md) {
  .social-icons {
    margin-top: 0;
    width: 100%;
    flex-direction: column;

    a {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      border: 1px solid $gray-blue-light;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      display: inline;
      margin-left: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: darken($gray-blue, 3%);
    }
  }

  .copyright {
    margin-top: 0;
  }

  .footer {
    padding: 40px 0;

    &__terms-links {
      .footer__section-link {
        margin-bottom: 20px;
      }
    }

    &__column-3 {
      padding-left: 50px;
    }

    &__column-1,
    &__column-4 {
      padding-left: 15px;
    }

    &__column-5 {
      padding-left: 0;
    }

    &__column-6 {
      display: block;
    }
  }
}

@include media-breakpoint-between(md, md) {
  .hide-on-md {
    display: none;
  }

  .address {
    width: 100%;
  }

  .footer {
    &__column-6 {
      display: block;
    }
  }
}

@include media-breakpoint-down(sm) {
  .hide-on-md {
    display: block;
  }

  .address {
    margin-top: 20px;
    width: 100%;
  }

  .copyright {
    width: 100%;
    margin-top: 20px;
  }

  .footer {
    &__column-1,
    &__content {
      padding-left: 0;
    }

    &__column-2,
    &__column-3 {
      padding-left: 0;
    }

    &__column-4 {
      padding-left: 0;
      flex: 0 0 100%;
      margin: 40px 0;
    }

    &__column-4 &__section-link {
      margin-top: 40px;
      display: inline-block;
      width: 50%;
    }

    &__column-6 {
      display: none;
    }
  }
}
