@import '../../styles/variables';
@import '../../styles/mixins';

.hero {
  display: flex;
  background: #070f5f;
  padding: 135px 0;
  overflow: hidden;
  position: relative;

  &__container {
    position: relative;
    z-index: 20;
    text-align: center;

    .hero__content {
      will-change: transform;
      display: flex;
      flex-direction: column;
    }

    h1 {
      text-transform: uppercase;
      color: $white;
      letter-spacing: 2.4px;
    }

    p {
      color: $white;
      margin: 20px 0;
      font-size: 24px;
      line-height: 38px;
      white-space: pre-line;
    }
  }

  &__background {
    position: absolute;
    z-index: 10;
    width: 110%;
    height: 110%;
    left: -5%;
    top: -5%;
    will-change: transform;
  }

  &--without-animation {
    overflow: visible !important;

    .hero__background {
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero {
    &__container {
      h1 {
        font-size: 70px;
        line-height: 88px;
      }

      p {
        font-size: 23px;
        line-height: 36px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .hero {
    padding: 80px 0;

    &__container {
      h1 {
        font-size: 58px;
        line-height: 70px;
        letter-spacing: 1.6px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .hero {
    padding: 80px 30px;

    &__container {
      text-align: center;

      h1 {
        letter-spacing: 1.5px;
        font-size: 48px;
        line-height: 62px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .hero {
    &__content {
      text-align: left;

      h1 {
        font-size: 42px;
        line-height: 52px;
        letter-spacing: 1px;
      }
    }
  }
}
