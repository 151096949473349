@import 'bootstrap/scss/_functions';

$spacer: 5px !default;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
) !default;

// Default colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Brand colors
$white-blue: #f0f4f7 !default;
$light-blue: #0cf !default;
$dark-blue: #2150d4 !default;
$darken-blue: #000b76 !default;
$darkest-blue: #000648 !default;

$dark-red: #cf3a51 !default;

$basic-gray: #333 !default;
$gray-blue: #546e7a !default;
$gray-blue-light: #607d8b !default;

$primary: $dark-blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'default': $gray-blue,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
  ),
  $theme-colors
);

$body-bg: $white !default;
$section-bg: $white-blue !default;

// Typography

$display-font-family: 'Bebas Neue';
$regular-font-family: 'Proxima Nova', -apple-system, system-ui, sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-base: 400 !default;
$line-height-base: 1.428571429 !default;

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1.25rem !default;
$input-btn-font-family: $regular-font-family !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$font-family-h1: $display-font-family !default;
$font-weight-h1: 700 !default;

$font-family-h2: $display-font-family !default;
$font-weight-h2: 700 !default;

$font-family-h3: $regular-font-family !default;
$font-weight-h3: 300 !default;

$font-family-h4: $regular-font-family !default;
$font-weight-h4: 600 !default;

$font-family-h5: $regular-font-family !default;
$font-weight-h5: 300 !default;

$font-family-h6: 'PT Serif', sans-serif !default;
$font-weight-h6: 400 !default;

// Grids
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
) !default;

// Forms

$input-btn-focus-width: 2px !default;
$input-btn-focus-color: rgba($primary, 0.2) !default;
$input-btn-line-height: $line-height-base;

$btn-font-weight: 600;

$label-margin-bottom: 0.5rem !default;
$input-font-weight: 500 !default;
$input-color: $gray-700 !default;
$input-placeholder-color: $gray-500 !default;
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$input-label-font-size: 15px !default;
$input-label-color: $gray-blue !default;
$input-label-line-height: 24px !default;
$input-label-font-weight: 600 !default;
$input-label-margin-bottom: 0.25rem !default;
$input-valid-color: $success !default;
$input-invalid-color: $danger !default;

$input-space-after: 5px !default;
$input-helper-font-size: 14px !default;
$input-helper-color: $gray-blue-light !default;
$input-helper-line-height: 20px !default;
$input-helper-font-weight: normal !default;
$input-disabled-border-color: null !default;

$input-bg: $white-blue !default;
$input-disabled-bg: #f4f5f7 !default;
$input-disabled-border-color: #edeef0 !default;
$input-border-color: darken($white-blue, 3%) !default;
$input-focus-bg: $white !default;
$input-focus-border-color: rgba(146, 154, 158, 0.3) !default;
$input-btn-focus-color: rgba($primary, 0.1) !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-group-padding: 10px 0 !default;
$input-group-addon-bg: #e3e9ed !default;

$input-btn-padding-y-lg: 0.65rem !default;
$input-btn-padding-x-lg: 2rem !default;
$input-btn-font-size-lg: 1.1rem !default;

$skeleton-base-color: $gray-200;
$skeleton-highlight-color: $gray-100;

$range-picker-hover: $white-blue !default;
$range-picker-selected: $primary !default;

@import 'bootstrap/scss/_variables';
