@import '../../../../styles/variables';

.root {
  display: inline-block;
  border-radius: 6px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.1);
  background-color: $white;
  z-index: 9999;

  &--dayPicker {
    margin: 0 !important;
  }

  .head {
    &_cell {
      color: $gray-700 !important;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
  }

  .months {
    padding: 15px;
  }

  .caption_label {
    font-weight: 500;
    font-size: 1.15em;
  }

  .nav_button {
    &:focus-visible {
      background-color: $range-picker-hover !important;
      border: none !important;
    }
  }

  .head_cell {
    padding: 0.5em;
    color: $gray-500;
    font-weight: 400;
    text-align: center;
    font-size: 0.875em;
    text-transform: none;
  }

  .cell {
    min-width: var(--rdp-cell-size);
  }

  .day {
    cursor: pointer;
    border-radius: 5px !important;
    text-align: center;
    vertical-align: middle;
    border: 2px solid white;
    width: calc(var(--rdp-cell-size) - 2px);
    height: calc(var(--rdp-cell-size) - 2px);
    margin: auto;

    &:hover {
      background-color: $range-picker-hover;
    }

    &:focus {
      outline: none;
      border-color: rgba($primary, 0.2);
    }

    &:focus-visible {
      background-color: $range-picker-hover !important;
      border: none !important;
    }

    &_today {
      color: #d0021b;
      font-weight: 700;
    }
  }

  .modifiers {
    &--selected {
      font-weight: bold;
      border: 2px solid $primary;
      color: $primary;
      background: rgba($primary, 0.1);

      &:hover {
        background: rgba($primary, 0.2) !important;
      }
    }
  }
}
