@import '../../styles/variables';
@import '../../styles/mixins';

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $gray-700;
  text-align: center;
  transition: background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  appearance: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  -webkit-font-smoothing: antialiased;

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: $btn-focus-box-shadow;
}

.btn:disabled,
.btn.disabled {
  opacity: 0.65;
  pointer-events: none;
  cursor: default !important;
  box-shadow: none !important;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);

    &:hover {
      background: rgba($value, 0.15);
      color: $value;
    }

    &:focus {
      background: rgba($value, 0.2) !important;
      color: $value !important;
    }

    &.active {
      background: $value !important;
      color: $white !important;
    }
  }
}

.btn-outline-secondary {
  border-color: $gray-300;
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: 0.5rem;
  }
}

.btn.btn-icon {
  padding: $btn-padding-y $btn-padding-y;
  border-radius: 50%;

  > svg {
    width: 18px;
    height: 18px;
  }

  &.btn-sm {
    padding: $btn-padding-y-sm $btn-padding-y-sm;
  }

  &.btn-lg {
    padding: $btn-padding-y-lg $btn-padding-y-lg;
  }
}

.loading-circle {
  animation: rotation 1s infinite;

  @include position(absolute, 50%, auto, auto, 50%);

  margin-left: -0.5em;
  margin-top: -0.5em;
  color: inherit;
}

.transparent {
  opacity: 0;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}
