@import 'mixins';
@import 'variables';

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova/proxima_nova_thin.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/ProximaNova/proxima_nova_thin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ProximaNova/proxima_nova_thin.ttf') format('truetype'),
    url('/fonts/ProximaNova/proxima_nova_thin.woff') format('woff'),
    url('/fonts/ProximaNova/proxima_nova_thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova/proxima_nova_light.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/ProximaNova/proxima_nova_light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ProximaNova/proxima_nova_light.ttf') format('truetype'),
    url('/fonts/ProximaNova/proxima_nova_light.woff') format('woff'),
    url('/fonts/ProximaNova/proxima_nova_light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova/proxima_nova_regular.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/ProximaNova/proxima_nova_regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ProximaNova/proxima_nova_regular.ttf') format('truetype'),
    url('/fonts/ProximaNova/proxima_nova_regular.woff') format('woff'),
    url('/fonts/ProximaNova/proxima_nova_regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova/proxima_nova_regular_italic.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/ProximaNova/proxima_nova_regular_italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ProximaNova/proxima_nova_regular_italic.ttf') format('truetype'),
    url('/fonts/ProximaNova/proxima_nova_regular_italic.woff') format('woff'),
    url('/fonts/ProximaNova/proxima_nova_regular_italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova/proxima_nova_semibold.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/ProximaNova/proxima_nova_semibold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ProximaNova/proxima_nova_semibold.ttf') format('truetype'),
    url('/fonts/ProximaNova/proxima_nova_semibold.woff') format('woff'),
    url('/fonts/ProximaNova/proxima_nova_semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue/bebasneue_bold.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/BebasNeue/bebasneue_bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BebasNeue/bebasneue_bold.ttf') format('truetype'),
    url('/fonts/BebasNeue/bebasneue_bold.woff') format('woff'),
    url('/fonts/BebasNeue/bebasneue_bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue/bebasneue_book.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/BebasNeue/bebasneue_book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BebasNeue/bebasneue_book.ttf') format('truetype'),
    url('/fonts/BebasNeue/bebasneue_book.woff') format('woff'),
    url('/fonts/BebasNeue/bebasneue_book.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Serif';
  src: url('/fonts/PTSerif/PTSerif-Italic.eot');
  src:
    local('PT Serif Italic'),
    local('PTSerif-Italic'),
    url('/fonts/PTSerif/PTSerif-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/PTSerif/PTSerif-Italic.ttf') format('truetype'),
    url('/fonts/PTSerif/PTSerif-Italic.woff') format('woff'),
    url('/fonts/PTSerif/PTSerif-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PT Serif';
  src: url('/fonts/PTSerif/PTSerif-Regular.eot');
  src:
    local('PT Serif'),
    local('PTSerif-Regular'),
    url('/fonts/PTSerif/PTSerif-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/PTSerif/PTSerif-Regular.ttf') format('truetype'),
    url('/fonts/PTSerif/PTSerif-Regular.woff') format('woff'),
    url('/fonts/PTSerif/PTSerif-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: $regular-font-family;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

h1,
.h1 {
  font-size: $h1-font-size;
  font-family: $font-family-h1;
  font-weight: $font-weight-h1;
}

h2,
.h2 {
  font-size: $h2-font-size;
  font-family: $font-family-h2;
  font-weight: $font-weight-h2;
}

h3,
.h3 {
  font-size: $h3-font-size;
  font-family: $font-family-h3;
  font-weight: $font-weight-h3;
}

h4,
.h4 {
  font-size: $h4-font-size;
  font-family: $font-family-h4;
  font-weight: $font-weight-h4;
}

h5,
.h5 {
  font-size: $h5-font-size;
  font-family: $font-family-h5;
  font-weight: $font-weight-h5;
}

h6,
.h6 {
  font-size: $h6-font-size;
  font-family: $font-family-h6;
  font-weight: $font-weight-h6;
  font-style: italic;
}

a {
  font-family: $regular-font-family;
  color: $link-color;
  text-decoration: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.regular-link,
:global(.regular-link) {
  cursor: pointer;
  color: $primary;
  position: relative;
  padding-bottom: 2px;
  text-decoration: none !important;

  &::after {
    @include pseudo();

    width: 100%;
    bottom: 0;
    height: 1px;
    left: 0;
    background: rgba($primary, 0.35);
  }

  &:hover {
    color: darken($dark-blue, 5%);

    &::after {
      background: rgba($primary, 0.5);
    }
  }

  &:focus {
    outline: none;

    &::after {
      height: 2px;
    }
  }

  &:active {
    color: darken($dark-blue, 10%);
  }
}

@mixin vertical-text-truncate($line-clamp: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include media-breakpoint-down(sm) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
    border-radius: 4px !important;
  }
}
