@import 'bootstrap/scss/_mixins';

@mixin position($type, $top: auto, $right: auto, $bottom: auto, $left: auto, $width: auto, $height: auto) {
  position: $type;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}

@mixin text($size: $font-size-base, $weight: 400, $line-height: 1.5, $color: inherit, $family: inherit) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
  font-family: $family;
}

//div::after {
//  @include pseudo;
//}
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin removeAutoFillBackground() {
  &:-webkit-autofill {
    transition: background-color 5000000s ease-in-out 0s;
  }
}
