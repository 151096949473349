@import '../../styles/variables';
@import '../../styles/mixins';

.slider {
  position: relative;
  width: 100%;
  user-select: none;
  transition: height 0.3s ease;
  overflow: hidden;

  &--with-dots {
    padding-bottom: 70px;
  }

  &__slide-page {
    width: 100%;
    position: absolute;
    display: flex;
    top: 0;
  }

  &__slide-page,
  &__slide {
    will-change: transform;
  }

  &__slide {
    display: flex;
    flex: 1;
  }

  &__controls {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -60px;

    button {
      position: absolute;
      outline: none !important;
      box-shadow: none !important;
      padding: 15px;
      background: none;
      border: none;
      appearance: none;

      svg {
        width: 40px;
        height: 40px;
        opacity: 0.5;
        transition: all 0.3s ease-in;
      }

      &:focus img,
      &:hover img {
        opacity: 0.75;
      }

      &:disabled svg {
        opacity: 0.25 !important;
      }

      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(2) {
        right: 0;
      }
    }
  }

  &__dots {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    list-style: none;
    display: flex;
    padding: 0;
    justify-content: center;
    cursor: pointer;

    &-dot {
      padding: 5px;

      &::before {
        @include pseudo(block, relative);

        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $gray-400;
      }

      &--active::before {
        background: $primary;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .slider {
    &--with-arrows {
      .slider__slide-page {
        padding-left: 100px;
        padding-right: 100px;
      }
    }

    &__controls {
      button {
        position: absolute;
        outline: none !important;
        box-shadow: none !important;
        padding: 15px;
        background: none;
        border: none;

        svg {
          width: 40px;
          height: 40px;
          opacity: 0.5;
          transition: all 0.3s ease-in;
        }

        &:focus img,
        &:hover img {
          opacity: 0.75;
        }

        &:disabled svg {
          opacity: 0.25 !important;
        }

        &:nth-child(1) {
          left: 0;
        }

        &:nth-child(2) {
          right: 0;
        }
      }
    }
  }
}
