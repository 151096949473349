@import '../../../styles/variables';
@import '../../../styles/mixins';

$modal-title-line-height: $line-height-base !default;
$modal-dialog-padding: 20px !default;
$modal-dialog-border-radius: 3px !default;
$modal-dialog-background: $white !default;
$modal-dialog-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !default;

.modal-dialog {
  padding: $modal-dialog-padding;
  border-radius: $modal-dialog-border-radius;
  background: $modal-dialog-background;
  box-shadow: $modal-dialog-box-shadow;

  // Fix for "jumping text" on scale
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;

  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 2px;
  }

  &__header {
    display: flex;
    margin-bottom: 24px;
  }

  &__close {
    @include position(absolute, 4px, 4px);

    padding: 10px 15px;
    cursor: pointer;
    text-shadow: 0 0 0 #fff;
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
    box-shadow: none !important;
    color: black;

    svg {
      padding: 3px;
      width: 24px !important;
      height: 24px !important;
      border-radius: 50%;
      border: 1px solid transparent;
    }

    &:hover {
      opacity: 0.65;
      text-decoration: none;

      svg {
        border-color: $gray-300;
      }
    }

    &:focus {
      opacity: 0.7;

      svg {
        border: 1px solid rgba($primary, 0.5);
        box-shadow: 0 0 1px $primary;
      }
    }
  }
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin-top: 10px;

  @include media-breakpoint-down(xs) {
    flex-direction: column;

    button {
      margin-top: 7px;
      margin-bottom: 7px;
      padding: 11px;
    }
  }

  button {
    margin-left: 15px;
  }
}

@include media-breakpoint-down(xl) {
  .modal-dialog {
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
