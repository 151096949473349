@import '../../styles/variables';
@import '../../styles/mixins';

$nav-bar-height: 84px;
$nav-bar-footer-height: 66px;
$nav-bar-height-tablet: 60px;
$nav-bar-height-mobile: 52px;

.nav_bar {
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;

  &__holder {
    position: sticky;
    z-index: $zindex-fixed;
    top: 0;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 2px 0 rgba(5, 27, 43, 0.14);
    z-index: $zindex-fixed + 2;
    background: $white;
  }

  &__skeleton {
    margin-left: 30px;

    &__item {
      margin-right: 30px;
    }
  }

  &__brand {
    flex-grow: 1;

    &__logo {
      height: $nav-bar-height;
      display: flex;
      justify-content: center;

      img {
        width: 135px;
      }
    }
  }

  &__action-bar {
    margin-left: 40px;
  }

  &__item {
    display: flex;
    transform-origin: 0 0;
    will-change: tranform, opacity;
    margin-right: 30px;
    margin-bottom: -1px;

    a {
      color: $basic-gray;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none !important;
      overflow: hidden;
    }

    &:hover {
      border-bottom: 1px solid rgba(96, 125, 139, 0.35);
    }
  }

  &__links {
    margin-left: 30px;
    display: flex;
    flex: 0;

    & > div {
      flex-basis: auto !important;
      white-space: nowrap;
    }
  }

  &__buttons {
    display: flex;
    flex-grow: 0;
    flex-wrap: nowrap;
    margin-left: auto;

    button,
    a {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }

  &__toggle {
    cursor: pointer;
    height: 100%;
    padding-right: 15px;
    display: flex;
    align-items: center;
    font-size: 28px;
    color: mix($primary, $darken-blue);
    z-index: $zindex-fixed + 3;
    min-width: 40px;
  }

  &__drawer {
    will-change: transform;
    transform: translateY(-100%);
    transition: transform 0.25s ease;
    width: 100%;
    opacity: 0;
    padding: $nav-bar-height 15px 30px;
    position: absolute;
    z-index: $zindex-fixed + 1;
    box-shadow: 0 1px 2px 0 rgba(5, 27, 43, 0.14);
    background: $white;
    max-height: 100vh;
    overflow: auto;

    .nav_bar__buttons {
      transform: translateY(-50px);
      will-change: transform, opacity;
      opacity: 0;
    }

    &--open {
      transform: translateY(0);
      opacity: 1;

      .nav_bar__buttons {
        animation: fadeDown 0.75s ease-out normal forwards;
        transform: translateY(0);
        opacity: 1;
      }
    }

    .nav-bar__phone {
      margin-top: 50px;
      font-size: 16px;
      color: $gray-blue;
      padding: 0 20px;

      svg {
        width: 16px;
        margin-right: 10px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__select {
    padding-right: 30px;

    &-content {
      .nav_bar__list {
        background: white;
        border-radius: 5px;
        z-index: 1;
        padding: 10px 8px;
      }

      a {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 16px;
        line-height: 22px;
        color: $gray-blue;
        padding: 0 15px;
        white-space: pre;
        text-decoration: none !important;
        margin-right: 0;
        border-bottom: none !important;
      }
    }

    &-trigger {
      &__container {
        color: $basic-gray;
        font-weight: 600;
        font-size: 16px;
        text-decoration: none !important;

        span {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            width: 100%;
            left: 0;
            top: 100%;
            margin-bottom: -1px;
          }
        }

        svg {
          margin-left: 5px;
        }

        &:hover {
          span::after {
            border-bottom: 1px solid rgba(96, 125, 139, 0.35);
          }
        }
      }

      &__item {
        color: $basic-gray;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;

        > span {
          padding-right: 5px;
        }

        svg {
          padding: 2px 0;
          font-size: 15px;
          line-height: 16px;
          color: $gray-blue-light;
          transition: transform 0.2s ease-out;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    :global(.dropdown__arrow) {
      @include position(absolute, -10px, 16px, auto, auto, 0, 0);

      z-index: 10;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
  }

  &__item-postfix {
    font-size: 13px;
    line-height: 13px;
    font-feature-settings: 'ordn' on;
    color: $gray-blue;
    opacity: 0.5;
    padding-left: 10px;
  }

  &--no-decoration {
    text-decoration: none !important;
  }

  &__item-tag {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 0 0 5px;
    background: $primary;
    color: $white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    height: 16px;
    border-radius: 8px;
    padding: 0 4px;
  }
}

@keyframes fadeDown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  70% {
    opacity: 0.9;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
  }
}

@include media-breakpoint-up(xl) {
  .nav_bar {
    &__toggle,
    &__drawer {
      display: none;
    }

    &__buttons {
      height: 40px;
      padding-left: 0;
      flex-wrap: nowrap;
    }

    &__brand {
      flex-grow: 0;
    }

    &__select {
      &:hover {
        .nav_bar__select-trigger__item {
          svg {
            transform: rotate(180deg);
          }
        }

        .nav_bar__select-content {
          display: flex;
          flex-direction: column;
        }
      }

      &-content {
        a {
          display: flex;
          align-items: center;
          height: 40px;
          font-size: 16px;
          line-height: 22px;
          color: $gray-blue;
          padding: 0 15px;
          white-space: pre;
          text-decoration: none !important;

          &:active {
            .nav_bar__item-main-text {
              opacity: 0.5;
            }
          }
        }
      }

      &-trigger__item--active::after {
        border-bottom: 1px solid $primary !important;
      }
    }

    &__list {
      padding: 5px;
    }

    &__item--active {
      border-bottom: 1px solid $primary !important;

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .nav_bar {
    &__drawer {
      padding-top: $nav-bar-height;

      :global(.container) {
        padding: 15px 45px;
      }
    }

    &__links {
      padding: 0;
      flex-direction: column;
      margin-left: 15px;

      & > div {
        flex-basis: 100%;
        white-space: pre-line;
        flex-direction: column;
        max-height: 300px;
        flex-wrap: wrap;
      }
    }

    &__item {
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .nav_bar__list {
        padding: 0;
      }

      .nav_bar__item {
        flex-direction: row;
        justify-content: flex-start;

        > span {
          display: flex;
          flex-direction: column;
        }

        span {
          width: 100%;
          font-weight: 400;
          padding-left: 0;
        }
      }

      a {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        line-height: 100%;
        height: 45px;
        color: $basic-gray;
        font-weight: 700;
        width: fit-content;
      }

      &:hover {
        border-bottom: none;

        a {
          box-shadow: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .nav_bar {
    &__buttons {
      button {
        width: 100%;
        font-size: 16px;
        overflow: hidden;
      }
    }

    &__action-bar {
      margin-left: 15px;
    }

    :global(.btn) {
      padding: 10px 20px;
    }

    &__backdrop {
      :global(.container) {
        max-width: 100%;
        padding: 0 30px;
      }
    }

    &__drawer {
      padding-top: $nav-bar-height-tablet;

      :global(.container) {
        max-width: 100%;
      }

      .nav-bar__phone {
        padding: 0;
      }

      img {
        margin-left: -39px;
      }
    }

    &__brand {
      width: 100%;

      &__logo {
        height: $nav-bar-height-tablet;

        img {
          width: 105px;
          height: auto;
        }
      }
    }

    &__links {
      padding: 0 !important;
      padding-left: 15px;

      & > div {
        max-height: none;
      }
    }

    &__toggle {
      font-size: 24px;
    }

    &__item {
      a {
        padding: 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .nav_bar {
    &__brand {
      &__logo {
        height: $nav-bar-height-mobile;

        img {
          width: 90px;
          height: auto;
        }
      }
    }

    &__drawer {
      padding-top: $nav-bar-height-mobile;

      :global(.container) {
        padding: 0 15px;
      }

      img {
        display: none;
      }
    }

    &__buttons {
      flex-wrap: wrap;

      button {
        margin: 0 0 15px !important;
      }
    }

    &__links {
      padding: 0;
      flex-wrap: wrap;
    }

    &__phone-sm {
      margin: 15px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav_bar {
    &__links {
      flex-direction: row;

      & > div {
        flex-basis: 50%;
      }
    }
  }
}
