.inline-spinner {
  width: 100%;
  display: flex;
  margin: auto;
  height: 100%;
  justify-content: center;
  //padding: 50px;

  &__ring {
    position: relative;
  }
}
