@import '../../styles/variables';
@import '../../styles/mixins';

.breadcrumbs {
  padding: 25px 15px;
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;

  a {
    font: inherit;
  }

  svg {
    width: 5px !important;
    margin: 0 12px -1px;
  }
}
