@import '../../styles/mixins';
@import '../../styles/variables';

.notifications {
  &__container {
    @include position(fixed, unset, 30px, 10px, unset, 330px, auto);

    z-index: $zindex-tooltip;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__holder {
    will-change: opacity, height;
    margin-bottom: 20px;
    overflow: hidden;
    box-shadow: inset 0 0 200px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
  }

  &__message {
    display: flex;
    align-content: baseline;
    box-sizing: border-box;
    width: 330px;
    height: auto;
    padding: 10px 15px;
    position: relative;
    background: none;

    svg {
      margin-left: 10px;
      margin-right: 15px;
    }

    &__close-btn {
      cursor: pointer;
      pointer-events: all;
      outline: 0;
      border: none;
      background: transparent;
      display: flex;
      align-self: flex-end;
      margin: 0 -10px 0 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.5);

      svg {
        margin: 3px 6px;
      }
    }

    p {
      font-size: 14px;
      margin: 0 0 5px;
      line-height: 20px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .notifications {
    &__container {
      align-items: center;
    }

    &__message {
      width: 290px;
    }
  }
}

@keyframes notification_animation {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
