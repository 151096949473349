@import '../../../styles/variables';
@import '../../../styles/mixins';

.skeleton {
  background-color: $skeleton-base-color;
  background-image: linear-gradient(90deg, $skeleton-base-color, $skeleton-highlight-color, $skeleton-base-color);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  max-width: 100%;
  animation: skeleton 1.2s ease-in-out infinite;
}

:global(.hide-skeletons) {
  .skeleton {
    opacity: 0;
  }
}

@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}
