@import '../../../styles/variables';
@import '../../../styles/mixins';

.btn-group {
  display: inline-flex;
  vertical-align: middle;
  position: relative;

  > button {
    position: relative;
    flex: 0 1 auto;
    z-index: 1;
    margin-left: -1px;

    &:hover {
      z-index: 3;
    }

    &:focus {
      z-index: 4;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
