@import '../../styles/variables';
@import '../../styles/mixins';

.link {
  cursor: pointer;
  color: $primary;
  position: relative;
  padding-bottom: 2px;

  &::after {
    @include pseudo();

    width: 100%;
    bottom: 0;
    height: 1px;
    left: 0;
    background: rgba($primary, 0.35);
  }

  &:hover {
    color: darken($dark-blue, 5%);

    &::after {
      background: rgba($primary, 0.5);
    }
  }

  &:focus {
    outline: none;

    &::after {
      height: 2px;
    }
  }

  &:active {
    color: darken($dark-blue, 10%);
  }
}
