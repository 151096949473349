@import '../../styles/variables';

.reveal-animation {
  will-change: transform, opacity;
  overflow: hidden;

  &__container {
    display: block;
    overflow: hidden;
  }
}
