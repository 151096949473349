@import '../../styles/variables';
@import '../../styles/mixins';

.card {
  margin-bottom: 30px;
  padding: 0 15px;
  align-items: center;

  &__content {
    display: flex;
    background: $white;
    border: 2px solid $gray-200;
    transition: border-color 0.2s ease-in;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    overflow: hidden;
    opacity: 0.6;
    height: 100%;
    align-items: center;

    > svg {
      width: auto;
      height: 60px;
      margin-right: 15px;
      margin-top: -5px;
    }

    h4 {
      margin-bottom: 4px;
      word-break: break-word;
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      justify-content: space-between;

      a {
        font-weight: inherit;
      }
    }
  }

  &--enabled {
    .card__content {
      cursor: pointer;
      opacity: 1;

      &:hover {
        border-color: rgba($primary, 0.3);
      }
    }
  }
}
