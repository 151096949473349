@import '../../styles/variables';

.page {
  position: relative;
  min-height: 100vh;
  background: $white-blue;
  display: flex;
  flex-direction: column;
  font-family: $regular-font-family;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
}
