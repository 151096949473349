@import '../../styles/mixins';
@import '../../styles/variables';

$callout-bg-level: -10 !default;
$callout-color-level: 6 !default;

.callout {
  display: flex;
  width: 100%;
  padding: 16px 16px 16px 12px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    user-select: none;
    transform: translateY(3px);

    &--small {
      font-size: 16px;
      height: 20px;
      width: 20px;
    }

    &--normal {
      font-size: 20px;
      height: 24px;
      width: 24px;
    }

    &--large {
      font-size: 24px;
      height: 30px;
      width: 40px;
    }
  }

  &__content {
    max-width: 100%;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    caret-color: rgb(55, 53, 47);
    margin: 0 8px;
  }
}

@mixin callout-variant($background, $color) {
  color: $color;
  border: 1px solid $color;

  hr {
    border-top-color: darken($background, 5%);
  }
}

// Generate contextual modifier classes for colorizing the callout.
@each $color, $value in $theme-colors {
  .callout--#{$color} {
    @include callout-variant(
      theme-color-level($color, $callout-bg-level),
      theme-color-level($color, $callout-color-level)
    );
  }
}
