@import '../../styles/variables';
@import '../../styles/mixins';

.accordion {
  &__wrapper {
    position: relative;

    &--open {
      .accordion__header .accordion__chevron {
        transform: rotate(180deg);
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .accordion__chevron {
      color: $gray-blue-light;
      will-change: transform;
      transition: transform 0.2s ease-out;
    }

    &__content {
      display: flex;
      align-items: center;
      column-gap: 10px;
      flex: 1;
    }
  }

  &__section-holder {
    will-change: height, opacity;
    transition: height 0.4s ease-out, opacity 0.2s ease-in;
    overflow: hidden;
  }
}

@include media-breakpoint-down(sm) {
  .accordion {
    &__header {
      .accordion__chevron {
        font-size: 16px;
      }
    }

    &__section-holder {
      transition: height 0s ease-out, opacity 0.4s ease-in;
    }
  }
}
