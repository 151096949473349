@import '../../../styles/variables';
@import '../../../styles/mixins';

.drawer {
  aside {
    background: $white;
    padding: 40px 15px;
    will-change: transform;
    transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s, width 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transform-origin: 0 0;
    height: 100vh;
    overflow: auto;
  }

  &.drawer--narrow {
    max-width: 300px;
  }

  &.drawer--medium {
    max-width: 500px;
  }

  &.drawer--wide {
    max-width: 600px;
  }

  &.drawer--extended {
    max-width: 95vw;
  }

  &.drawer--full {
    max-width: 100vw;
  }

  &.drawer--open aside {
    transform: translateX(0);
  }

  &--left aside {
    transform: translateX(-100%);
  }

  &--right aside {
    transform: translateX(100%);
  }
}

.drawer__closer {
  display: flex;
  align-items: center;

  &--right {
    svg {
      transform: rotate(180deg);
    }
  }

  button {
    border-radius: 50%;
    margin-right: 20px;
    padding: 0;
    width: 38px;
    height: 38px;

    svg {
      margin-top: -1px;
    }

    &:hover {
      background: $gray-200;
    }
  }
}

@include media-breakpoint-down(md) {
  .drawer {
    &--wide {
      width: 90vw;
    }
  }
}

@include media-breakpoint-down(sm) {
  .drawer {
    &--narrow,
    &--medium {
      width: 90vw;
    }
  }
}
