@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

$display-font-family: 'Bebas Neue';
$regular-font-family: 'Proxima Nova', -apple-system, system-ui, sans-serif;

$font-size-sm: $font-size-base * 0.95;

$h1-font-size: 80px;
$font-family-h1: $display-font-family;
$font-weight-h1: 700;

$h2-font-size: 64px;
$font-family-h2: $display-font-family;
$font-weight-h2: 700;

$h3-font-size: 40px;
$font-family-h3: $regular-font-family;
$font-weight-h3: 300;

$h4-font-size: 26px;
$font-family-h4: $regular-font-family;
$font-weight-h4: 600;

$h5-font-size: 20px;
$font-family-h5: $display-font-family;
$font-weight-h5: 300;

$h6-font-size: 17px;
$font-family-h6: 'PT Serif', sans-serif;
$font-weight-h6: 400;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

@import '@visalex/core/src/styles/main';

p {
  margin: 10px 0;
  line-height: 32px;
  font-size: 20px;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 100%;

    .col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .row {
    margin-left: auto;
    margin-right: auto;
  }
}

@include media-breakpoint-down(lg) {
  .full-width-mobile {
    max-width: 100% !important;
  }
}
