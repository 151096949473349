@import '../../styles/variables';
@import '../../styles/mixins';

.tooltip {
  position: relative;

  &__text {
    white-space: nowrap;
  }
}

.tooltip--portaled :global(.dropdown__menu-content),
.tooltip :global(.dropdown__menu-content) {
  padding: 6px 10px !important;
}

$tooltip-dark-bg: rgba($black, 0.85);
$tooltip-arrow-bg: rgba($black, 0.82);

.tooltip-dark {
  &__menu {
    background: $tooltip-dark-bg !important;
    font-weight: 400;
    font-size: 14px;
    color: #e8e8e8;
    text-align: center;

    &.dropdown--top :global(.dropdown__arrow),
    &.dropdown--top-start :global(.dropdown__arrow),
    &.dropdown--top-end :global(.dropdown__arrow) {
      border-top-color: $tooltip-arrow-bg;
    }

    &.dropdown--bottom :global(.dropdown__arrow),
    &.dropdown--bottom-start :global(.dropdown__arrow),
    &.dropdown--bottom-end :global(.dropdown__arrow) {
      border-bottom-color: $tooltip-arrow-bg;
    }

    &.dropdown--left :global(.dropdown__arrow),
    &.dropdown--left-start :global(.dropdown__arrow),
    &.dropdown--left-end :global(.dropdown__arrow) {
      border-left-color: $tooltip-arrow-bg;
    }

    &.dropdown--right :global(.dropdown__arrow),
    &.dropdown--right-start :global(.dropdown__arrow),
    &.dropdown--right-end :global(.dropdown__arrow) {
      border-right-color: $tooltip-arrow-bg;
    }
  }
}
