@import '../../styles/variables';
@import '../../styles/mixins';

.action-bar {
  width: 100%;
  background-color: $gray-300;
  z-index: $zindex-fixed + 1;
  position: relative;

  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    color: $gray-blue;
  }

  &__item {
    line-height: 20px;
    padding-left: 30px;
    margin-left: 30px;
    align-items: center;
    display: inline-flex;

    svg {
      margin-right: 6px;
      font-size: 13px;
      color: darken($gray-blue, 5%);
    }

    a {
      color: darken($gray-blue, 5%);
    }

    &_border {
      border-left: 1px solid rgba($gray-800, 0.3);
    }
  }
}

@include media-breakpoint-down(md) {
  .action-bar {
    &__content {
      padding: 0 30px;
      justify-content: space-between;
    }

    &__item {
      padding: 0;
      margin: 0;
    }
  }
}
