@import '../../../styles/variables';
@import '../../../styles/mixins';

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

$dropdown-arrow-size: 8px !default;
$dropdown-menu-padding: 2px 4px !default;
$dropdown-element-border-radius: 3px !default;
$dropdown-element-padding: 2px 15px !default;

.dropdown {
  position: relative;
  display: inline-block;
  width: fit-content;
  vertical-align: middle;
  font-family: $regular-font-family;
  user-select: none;

  &__toggle {
    cursor: pointer;

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-chevron {
      width: 10px !important;
      margin-left: 5px;
    }

    svg {
      transition: transform 0.2s ease-out;
    }

    &--show {
      .dropdown__toggle-chevron {
        transform: rotate(180deg);
      }
    }

    &--disabled {
      cursor: default;
      color: $gray-500;

      &:focus {
        text-decoration: none;
      }
    }
  }

  &__arrow {
    position: absolute;
  }

  &__menu {
    position: absolute;
    top: 100%;
    z-index: $zindex-popover;
    display: none; // none by default, but block on "open" of the menu
    will-change: transform, opacity, height;
    transition: height 0.3s ease;
    max-width: 90vw;
    width: fit-content;
    padding: 0;
    margin: 0.125rem 0 0; // override default ul
    background-color: $white;
    border-radius: 4px;
    transform-origin: 0 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 50px 100px, rgba(50, 50, 93, 0.15) 0 15px 35px, rgba(0, 0, 0, 0.1) 0 5px 15px;

    &-content {
      padding: $dropdown-menu-padding;
      overflow: hidden;
      width: 100%;
    }

    :global(.dropdown__arrow) {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
    }

    &:not(.dropdown__menu--show) {
      left: -10000px !important; // required to resolve issue with horizontal scroll
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        width: 100%;
        border-radius: $dropdown-element-border-radius;
        padding: $dropdown-element-padding;
        font-size: $font-size-base;
        color: $gray-800;
        line-height: 30px;
        text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
        transition: all 0.3s;
        white-space: nowrap;
        cursor: pointer;
        margin-bottom: 2px;

        &:first-child {
          margin-top: 3px;
        }

        &:last-child {
          margin-bottom: 3px;
        }

        &:hover,
        &:focus {
          outline: none;
          background-color: rgba($primary, 0.15);

          > svg {
            opacity: 0.9;
          }
        }

        &:hover {
          background-color: rgba($primary, 0.1);
        }

        > svg,
        img {
          margin-right: 10px;
          width: 15px;
          height: 15px;
          position: relative;
          top: -1px;
          opacity: 0.8;
          transition: 0.3s opacity;
        }

        &.selected {
          font-weight: 600;
          background-color: rgba($primary, 0.2);
          color: darken($gray-800, 5%);

          > svg {
            // Check icons
            fill: $primary !important;
            opacity: 1;
          }
        }

        &.checked {
          padding-right: 30px;

          svg {
            position: absolute;
            right: 10px;
          }
        }

        &--disabled {
          color: $gray-400;
        }
      }
    }

    :global(.dropdown__arrow),
    :global(.dropdown__arrow)::after {
      border: $dropdown-arrow-size solid transparent;
    }

    &--show {
      display: flex;
    }
  }

  /* modifier */

  /**
   * Top
   */
  &--top,
  &--top-start,
  &--top-end {
    .dropdown__menu,
    &.dropdown__menu {
      margin-top: -12px;
    }

    :global(.dropdown__arrow) {
      border-top-color: $white;
      bottom: -$dropdown-arrow-size;
      left: 50%;
      margin-left: -$dropdown-arrow-size;
      border-bottom-width: 0;

      &::after {
        bottom: 1px;
        margin-left: -$dropdown-arrow-size;
        border-bottom-width: 0;
        border-top-color: $white;
      }
    }
  }

  &--top-start {
    left: 0;
    right: auto;

    :global(.dropdown__arrow) {
      left: $dropdown-arrow-size * 2;
    }
  }

  &--top-end {
    right: 0;
    left: auto;

    :global(.dropdown__arrow) {
      left: initial;
      right: $dropdown-arrow-size * 2;
    }

    .dropdown__menu,
    &.dropdown__menu {
      margin-left: 19px;
    }
  }

  /**
   * Bottom
   */
  &--bottom,
  &--bottom-start,
  &--bottom-end {
    .dropdown__holder {
      margin-top: -15px;
      padding-top: 15px;
    }

    :global(.dropdown__arrow) {
      top: -$dropdown-arrow-size;
      left: 50%;
      margin-left: -$dropdown-arrow-size;
      border-top-width: 0;
      border-bottom-color: $white;

      &::after {
        top: 1px;
        margin-left: -$dropdown-arrow-size;
        border-top-width: 0;
        border-bottom-color: $white;
      }
    }

    .dropdown__menu,
    &.dropdown__menu {
      margin-top: 12px;
    }
  }

  &--bottom-start {
    left: 0;
    right: auto;

    :global(.dropdown__arrow) {
      left: $dropdown-arrow-size * 2;
    }
  }

  &--bottom-end {
    right: 0;
    left: auto;

    :global(.dropdown__arrow) {
      left: initial;
      right: $dropdown-arrow-size * 2;
    }

    .dropdown__menu,
    &.dropdown__menu {
      margin-left: 19px;
    }
  }

  /**
   * Left
   */
  &--left {
    .dropdown__menu,
    &.dropdown__menu {
      margin-left: -$dropdown-arrow-size * 2;
    }

    :global(.dropdown__arrow) {
      top: 50%;
      right: 0;
      margin-top: -$dropdown-arrow-size;
      margin-right: -$dropdown-arrow-size;
      border-right-width: 0;
      border-left-color: $white;

      &::after {
        right: 1px;
        margin-top: -$dropdown-arrow-size;
        border-right-width: 0;
        border-left-color: $white;
      }
    }
  }

  /**
   * Right
   */
  &--right {
    .dropdown__menu,
    &.dropdown__menu {
      margin-left: $dropdown-arrow-size * 1.5;
    }

    :global(.dropdown__arrow) {
      top: 50%;
      left: 0;
      margin-top: -$dropdown-arrow-size;
      margin-left: -$dropdown-arrow-size;
      border-left-width: 0;
      border-right-color: $white;

      &::after {
        left: 1px;
        margin-top: -$dropdown-arrow-size;
        border-left-width: 0;
        border-right-color: $white;
      }
    }
  }

  &--full-width {
    .dropdown__menu {
      width: 100%;
    }
  }

  :global(&--no-wrap) {
    .dropdown__menu {
      width: fit-content;

      li {
        white-space: nowrap;
      }
    }
  }

  // Checkboxes
  ul li label {
    margin: 4px 0;

    i {
      width: 16px;
      height: 16px;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
      opacity: 0 !important;
    }

    input:checked ~ .checkbox__mark {
      svg {
        opacity: 1 !important;
      }
    }
  }

  &__option {
    column-gap: 10px;
    font-size: 15px;
    line-height: 28px;

    &--selected {
      font-weight: bold;
    }
  }
}

.dropdown__divider {
  margin: 5px auto;
  height: 1px;
  width: calc(100% - 30px);
  background: rgba($gray-blue, 0.3);

  &_with-text {
    margin-top: 10px;
    margin-bottom: 3px;
    padding-left: 15px;
  }

  &_text {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    color: rgba($gray-blue, 0.7);
  }
}
