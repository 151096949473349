@import '../../../styles/variables';
@import '../../../styles/mixins';

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-padding-gallery: 0 0 150px 0 !default;

$modal-padding-y: 0.5rem !default;
$modal-padding-y-sm-up: 5% !default;

$modal-xl: 1200px !default;
$modal-lg: 800px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;

.modal {
  z-index: $zindex-modal + 1;
  position: relative;
  width: 100%;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  &__container,
  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
  }

  &__container {
    overflow: auto;
    z-index: $zindex-modal;
    display: flex;
    padding: $modal-padding-y 0;
  }

  &__backdrop {
    // Prevent Chrome on Windows from adding a focus outline.
    outline: 0;
    background-color: rgba($black, 0.5);
  }

  &.modal-full-height {
    position: fixed;
    height: 100%;
    // TODO: should be checked why now it makes drawers to open on full screen
    //max-width: none !important;
    top: 0;

    > div {
      height: 100%;
      border-radius: 0;
    }
  }

  &.modal-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.modal-right {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@include media-breakpoint-down(md) {
  .modal {
    margin: auto;

    &__container {
      padding: 15px;
      display: flex;
    }
  }
}

@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal {
    max-width: $modal-md;
    margin: auto;

    &__container {
      padding: $modal-padding-y-sm-up 0;
    }
  }

  .modal-centered {
    min-height: calc(100% - #{$modal-padding-y-sm-up * 2});

    &::before {
      height: calc(100vh - #{$modal-padding-y-sm-up * 2});
    }
  }

  .modal-sm {
    max-width: $modal-sm;
  }
}

@include media-breakpoint-up(lg) {
  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: $modal-xl;
  }
}
